<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-combobox :label="tLabel('Object code')" :items="params.objectCode" clearable v-model="objectCode" />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          :label="tLabel('Owner')"
          :items="listOwners"
          item-text="code"
          item-value="id"
          clearable
          v-model="ownerCompanyId"
        />
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import YardEnumMixin from "@/mixins/yard/EnumMixin";
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "MergeGoods",
  props: ["visits"],
  data() {
    return {
      splits: null,
      params: {},
      objectCode: null,
      ownerCompanyId: null,
      listOwners: [],
      owners:[],
    };
  },
  computed: {
  },
  async mounted() {
    this.owners= await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
    this.initForm();
  },

  methods: {
    getParams() {
      return { objectCode: this.objectCode, ownerCompanyId: this.ownerCompanyId };
    },
    setParams(value) {
      this.params=value.params.params;
      this.initForm();
      
    },
    initForm(){
      if(!this.params.ownerCompany) return;
      this.objectCode=this.params.objectCode.length==1 ? this.params.objectCode[0]:null;
      this.listOwners=this.owners.filter(o=>this.params.ownerCompany.includes(o.id.toString()));
      this.ownerCompanyId=this.params.ownerCompany.length==1 ? parseInt(this.params.ownerCompany[0]):null;
    },
  },
  filters: {
  },
  components: {},
  mixins: [YardEnumMixin, FormatsMixins],
};
</script>

<style scoped></style>
