<template>
  <v-dialog v-model="dialogVisible" scrollable width="1000">
    <v-card v-if="action">
      <v-card-title class="panelheader text-uppercase">
        {{ action.title }}
        <v-spacer />
        <v-icon @click="dialogVisible = false" large>mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row align="end" class="text-center text-caption text-uppercase py-3">
          <v-col cols="4"/>
          <v-col cols="4">{{ tLabel("Visite processabili") }} : {{ params.processed }} / {{ params.processed + params.skipped }}</v-col>
          <v-col cols="4">{{ tLabel("Visite non processabili") }} : {{ params.skipped }} / {{ params.processed + params.skipped }}</v-col>
        </v-row>
        <v-divider/>
        <v-row class="pt-5">
          <v-col cols="12">
            <v-form ref="actionForm">
              <component :is="component" :visits="visits" ref="comp" />
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="errorMessage">
          <v-col cols="12"><p class="errorMsg">{{ errorMessage }}</p></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialogVisible = false" large >{{ tLabel("Cancel") }}</v-btn>
        <v-btn primary @click="execAction" large :disabled="params.processed == 0" >{{ tLabel("Esegui") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SetHandlingOutTrain from "@/components/yard/actions/SetHandlingOutTrain";
import SetHandlingOutTruck from "@/components/yard/actions/SetHandlingOutTruck";
import SplitGood from "@/components/yard/actions/SplitGood";
import MergeGoods from "@/components/yard/actions/MergeGoods";


export default {
  name: "ActionDialog",
  props: ["action", "visits"],
  data() {
    return {
      params: {},
      dialogVisible: false,
      errorMessage: null,
    };
  },
  computed: {
    component() {
      return this.action.component || this.action.action;
    },
  },
  mounted() {},

  methods: {
    getParams() {
      return this.$refs.comp.getParams();
    },
    setParams(params) {
      this.params = params;
      return this.$refs.comp.setParams(params);
    },
    execAction() {
      this.$emit("exec-action");
    },
  },
  components: { SetHandlingOutTrain, SetHandlingOutTruck, SplitGood, MergeGoods },
  mixins: [],
};
</script>

<style scoped>
.errorMsg{
  color: white;
  background: red;
  margin: 5px;
  padding: 5px;
}
</style>
