<template>
  <div>
    <div class="content-section introduction">
      <div class="feature-intro">
        <h1>{{ tLabel("All visits") }}</h1>
      </div>
    </div>

    <v-container fluid class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <v-col cols="2">
          <v-text-field :label="tLabel('Unit number')" v-model="filters['B.object_codeLike']" @input="(val) => (filters['B.object_codeLike'] = val ? val.toUpperCase() : null)"/>
        </v-col>
        <v-col cols="2">
          <div class="text-body-1">{{tLabel('Sys type')}}</div>
          <v-btn v-for="(sysType, index) in sysTypesFiltered" :key="index" tile :color="isSelected('sysTypeIn', sysType.value) ? 'primary' : null" @click="toggleFilter('sysTypeIn', sysType.value)" min-width="30">
            <v-icon :title="sysType.text">{{ iconMap[sysType.value] }} {{index}}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-autocomplete clearable :label="tLabel('Tipo Unit')" v-model="filters.type_code" :items="unitTypes" item-text="text" item-value="value"> </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Voyage In/Out')" v-model="filters.voyagesLike" @input="(val) => (filters.voyagesLike = val ? val.toUpperCase() : null)"/>
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('Visit status')" v-model="filters.visitStatusIn" :items="visitStatus" multiple />
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('Visit role')" v-model="filters.visitRoleIn" :items="roles" multiple />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="2">
          <DateTimeAdapter :label="tLabel('Data in Da')" defaultTime="00:00:00" v-model="filters['b.data_inAsDateGreatherThan']" />
        </v-col>
        <v-col cols="2">
          <DateTimeAdapter :label="tLabel('Data in A')" defaultTime="23:59:59" v-model="filters['b.data_inAsDateLessThan']" />
        </v-col>
        
        <v-col cols="2">
          <v-text-field :label="tLabel('Posizione')" v-model="filters.position" @input="(val) => (filters.position = val ? val.toUpperCase() : null)"/>
        </v-col>
        <v-col cols="2">
          <v-autocomplete :label="tLabel('Owner')" v-model="filters['B.owner_company_id']" :items="owners" item-value="id" item-text="code"/>
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('Full/Empty')" v-model="filters['uv.full_empty']" :items="fullEmpty" />
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('Danni')" v-model="filters.damaged" :items="yesNoList" />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="2">
          <v-select :label="tLabel('Pericoloso')" v-model="filters.pericoloso" :items="dangerousFlg" />
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('Position status')" v-model="filters.positionStatus" :items="positionStatus" />
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Provenienza')" v-model="filters.departure" />
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Destinazione')" v-model="filters.destination" />
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Carrier out')" v-model="filters.carrier_code_out" @input="(val) => (filters.carrier_code_out = val ? val.toUpperCase() : null)"/>
        </v-col>
      </v-row>
    </v-container>

    <v-toolbar dense flat>
      <div>
        <span class="text-caption text-uppercase">{{selectedUnitMessage}}</span>
        <a @click="selectAllItems" v-if="showSelectAllItems"> ( {{$t("Seleziona tutte le {n} units", {n:this.totalRecords})}} ) </a>    
      </div>
      <v-spacer />
      <v-btn icon :title="tLabel('Applica filtri')" @click="onRefresh">
        <v-icon color="warning" medium>mdi-find-replace</v-icon>
      </v-btn>
      <v-btn icon :title="tLabel('Cancella filtri')" @click="onCleanFilters">
        <v-icon color="warning" medium>mdi-broom</v-icon>
      </v-btn>
      <v-btn icon :title="btn_title_exportCSV" @click="exportCSV">
        <v-icon color="success" medium>mdi-file-table-outline</v-icon>
      </v-btn>
      <v-menu offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" :disabled="!selected || selected.length===0" small>
            {{tLabel('Operations')}}
          </v-btn>
        </template>
        <div>
          <v-list>
            <v-list-item @click="showAction(item)" v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </v-toolbar>

    <v-data-table
      v-model="selected"
      :headers="columnsHeader"
      :items="items"
      ref="tosDataTable"
      hide-default-footer
      show-select
      :footer-props="{
        itemsPerPageOptions: [-1],
      }"
      @toggle-select-all="onSelectAllItems"
      @item-selected="onSelectItem"
      class="infiniteTable"
    >

      <template v-slot:item.actions="{ item }">
        <div class="text-left">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
            <v-list-item @click="showDetails(item)">
              <v-list-item-title class="text-uppercase">{{ tLabel("View details") }}</v-list-item-title>
              <v-list-item-action>
                <v-icon  color="secondary">mdi-information-outline</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item @click="showSingleAction(action, item)" v-for="(action, index) in singleActions" :key="index">
              <v-list-item-title>{{ action.title }}</v-list-item-title>
            </v-list-item>

          </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:item.sysType="{ item }">
        <v-icon large :title="sysTypesMap[item.sysType]">{{ iconMap[item.sysType] }}</v-icon>
      </template>

      <template v-slot:item.objectCode="{ item }">
        <span class="text-h6">{{ item.objectCode }} </span>
      </template>

      <template v-slot:item.status="slotProps">
        <EnumVisualization :value="slotProps.value" :enums="visitStatus" :enumsMap="visitStatusMap" showOnlyValue />
      </template>

      <template v-slot:item.positionStatus="slotProps">
        <EnumVisualization :value="slotProps.value" :enums="positionStatus" :enumsMap="positionStatusMap" showOnlyValue />
      </template>

      <template v-slot:item.customsStatus="slotProps">
        <EnumVisualization :value="slotProps.value" :enums="customsStatus" :enumsMap="customsStatusMap" showOnlyValue />
      </template>

      <template v-slot:item.fullEmpty="slotProps">
        <EnumVisualization :value="slotProps.value" :enums="fullEmpties" :enumsMap="fullEmptiesMap" showOnlyValue />
      </template>

      <template v-slot:item.dangerous="slotProps">
        <div>
          <v-icon v-if="slotProps.value === true" color="warning" large>mdi-alert</v-icon>
        </div>
      </template>

      <template v-slot:item.dataIn="slotProps">
        {{ slotProps.value | formatDateTimeFromDate }}
      </template>

      <template v-slot:item.hinVoyageCode="{ item }">
        <div v-for="h in item.handlingsIn" v-bind:key="h.id">
          {{ h.voyageCode }}
        </div>
      </template>

      <template v-slot:item.hinTransportType="{ item }">
        <div v-for="h in item.handlingsIn" v-bind:key="h.id">
          <v-icon large :title="transportTypesMap[h.transportType]">{{ iconMap[h.transportType] }}</v-icon>
        </div>
      </template>

      <template v-slot:item.houtVoyageCode="{ item }">
        <div v-for="h in item.handlingsOut" v-bind:key="h.id">
          {{ h.voyageCode }}
        </div>
      </template>

      <template v-slot:item.houtCarrierCode="{ item }">
        <div v-for="h in item.handlingsOut" v-bind:key="h.id">
          {{ h.carrierCode }}
        </div>
      </template>

      <template v-slot:item.houtTransportType="{ item }">
        <div v-for="h in item.handlingsOut" v-bind:key="h.id">
          <v-icon large :title="transportTypesMap[h.transportType]">{{ iconMap[h.transportType] }}</v-icon>
        </div>
      </template>

      <template v-slot:item.houtPlannedDate="slotProps">
        {{ slotProps.value | formatDateTimeFromDate }}
      </template>

      <template v-slot:item.dataOut="slotProps">
        {{ slotProps.value | formatDateTimeFromDate }}
      </template>

      <template v-slot:footer>
        <infinite-loading @infinite="doLoadPage" :identifier="infiniteId"></infinite-loading>
      </template>
      <template v-slot:#page-text></template>
    </v-data-table>

    <div class="totals">{{ totals }}</div>

    <v-dialog v-model="dialogInfoVisible" scrollable ref="viewDetail">
      <v-card>
        <v-card-title color="secondary">
          {{ tLabel("Details") }}
          <v-spacer />
          <v-icon @click="dialogInfoVisible = false" large>mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <VisitDetails v-if="item" :visitId="item.id" :visitSysType="item.sysType" standalone="false" v-on:visit-changed="onVisitChanged" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <ActionDialog :action="currentAction" :visits="selected" ref="actionDialog" @exec-action="execAction" />

  </div>
</template>

<script>
import FormatsMixins from "../../../mixins/FormatsMixins";
import YardEnumMixin from "@/mixins/yard/EnumMixin";
import CalendarFilter from "@/components/anagrafiche/CalendarFilter";

import RailTosService from "@/service/tos/RailTosService";
import ToastMixin from "../../../mixins/ToastMixin";
import InfiniteLoading from "vue-infinite-loading";
import EnumVisualization from "@/components/yard/EnumVisualization.vue";
import VisitDetails from "@/views/yard/inventory/VisitDetails.vue";
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";
import ActionDialog from "@/components/yard/actions/ActionDialog";

//import i18n from "../i18n";

export default {
  name: "allVisitsInfinite",
  props: {
    title: { default: "TUTTE LE  VISITE" },
    defaultFilters: { type: Object, default: () => {} },
  },
  data() {
    return {
      item: null,
      includeCarrier: false,
      defaultSort: { field: "INFINITE", desc: "1" },
      unitTypes: [],
      owners: [],
      fullEmpty: [
        { text: "FULL", value: "F" },
        { text: "EMPTY", value: "E" },
      ],
      dangerousFlg: [
        { text: this.tLabel("SI"), value: true },
        { text: this.tLabel("NO"), value: false },
      ],
      yesNoList: [
        { text: this.tLabel("SI"), value: "Y" },
        { text: this.tLabel("NO"), value: "N" },
      ],
      headers: [
        { text: ``, value: "actions" },
        { text: `${this.tLabel("Type")}`, value: "sysType" },
        { text: `${this.tLabel("Unit")}`, value: "objectCode" },
        { text: `${this.tLabel("Operator")}`, value: "owner" },
        { text: `${this.tLabel("Cycle")}`, value: "cycle" },
        { text: `${this.tLabel("Unit Type")}`, value: "typeCode" },
        { text: `${this.tLabel("ISO Size Type")}`, value: "isoSizeType" },
        { text: `${this.tLabel("Items")}`, value: "qty" },
        { text: `${this.tLabel("WGT")}`, value: "grossweight" },
        { text: `${this.tLabel("Status")}`, value: "status" },
        { text: `${this.tLabel("Position Status")}`, value: "positionStatus" },
        // { text: `${this.tLabel("Customs Status")}`, value: "customsStatus" },
        { text: `${this.tLabel("Full/Empty")}`, value: "fullEmpty" },
        { text: `${this.tLabel("UNDG")}`, value: "dangerous" },
        
        // { text: `${this.tLabel("Position")}`, value: "positionCode" },
        // { text: `${this.tLabel("Order")}`, value: "hinOrderCode" },
        { text: `${this.tLabel("In")}`, value: "hinTransportType" },
        { text: `${this.tLabel("Voyage In")}`, value: "hinVoyageCode" },
        { text: `${this.tLabel("Date In")}`, value: "dataIn", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Out")}`, value: "houtTransportType" },
        { text: `${this.tLabel("Voyage Out")}`, value: "houtVoyageCode" },
        { text: `${this.tLabel("Carrier Out")}`, value: "houtCarrierCode" },
        // { text: `${this.tLabel("Planned Date Out")}`, value: "houtPlannedDate", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Date Out")}`, value: "dataOut", formatValue: "formatDateTimeFromDate" },
      ],
      items: [],
      page: 0,
      itemsPerPage: 20,
      loading: false,
      doCount: false,
      service: new RailTosService("inventoryVisits", "yard"),
      filters: {},
      activeFilters: null,
      infiniteId: +new Date(),
      totals: null,
      totalRecords: null,
      dialogInfoVisible: false,
      sysTypes: [],
      sysTypesMap: {},
      visitStatus: [],
      visitStatusMap: {},
      positionStatus: [],
      positionStatusMap: {},
      customsStatus: [],
      customsStatusMap: {},
      fullEmpties: [],
      fullEmptiesMap: {},
      roles: [],
      rolesMap: {},
      unitTypes: [],
      selected: [],
      dialogActionVisible: false,
      currentAction: null,
      showSelectAllItems: false,
      isSelectAllItem: false,
      actions: [
        { name: 'set-handling-out-train', title: this.tLabel('Uscita treno'), action:"SetHandlingOutTrain" },
        { name: 'set-handling-out-track', title: this.tLabel('Uscita gomma'), action:"SetHandlingOutTruck" },
        { name: 'merge', title: this.tLabel('Merge'), action:"MergeGoods" },
      ],
      singleActions: [
        { name: 'split', title: this.tLabel('Split'), action:"SplitGood" },
      ],
    };
  },
  async mounted() {
    await this.loadCombo();
  },
  computed: {
    columnsHeader() {
      let c = [];
      this.headers.forEach(function (item) {
        c.push({ class: "header text-uppercase", ...item });
      });
      return c;
    },
    selectedUnitMessage(){
      if (this.isSelectAllItem) return `${this.tLabel("Tutte le units")} (${this.totalRecords}) `;
      // if (this.selected.length==0) return null;
      return `${this.tLabel("Unit selezionate")}: ${this.selected.length}`;
    },
    sysTypesFiltered(){
      return this.sysTypes.filter(e=> ['UNT','GOO','RRO','PER'].includes(e.value));
    },
  },
  methods: {
    async loadCombo() {
      this.unitTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "unit-types/comboList");

      let goodsType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "goods-types/comboList");
      this.unitTypes.push.apply(this.unitTypes, goodsType);

      let roroType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "rorotypes/comboList");
      this.unitTypes.push.apply(this.unitTypes, roroType);

      const all = await this.$api.yardData.getLookups();
      this.sysTypes = this.mapList(this.sysTypesMap, all.sysTypes);
      this.visitStatus = this.mapList(this.visitStatusMap, all.visitStatus);
      this.positionStatus = this.mapList(this.positionStatusMap, all.positionStatus);
      this.customsStatus = this.mapList(this.customsStatusMap, all.customsStatus);
      this.fullEmpty = this.mapList(this.fullEmptiesMap, all.fullEmpties);
      this.roles = this.mapList(this.rolesMap, all.roles);
      this.owners = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
    },
    mapList(map, list) {
      list.forEach((element) => {
        map[element.value] = element.text;
      });
      return list;
    },
    async infinite() {
      await this.doLoadPage(false);
      this.page++;
    },

    async doLoadPage($state) {
      const doCount = this.totalRecords == null;
      this.loading = true;
      if(this.activeFilters==null) this.activeFilters={...this.filters};
      try {
        const resp = await this.service.list(this.page * this.itemsPerPage, this.itemsPerPage, this.defaultSort.field, this.defaultSort.desc, this.activeFilters, doCount);
        if (resp.data.length) {
          this.page += 1;
          this.items.push(...resp.data);
          $state.loaded();
        } else {
          $state.complete();
        }
        if (doCount) this.totalRecords = resp.recordsFiltered;
        this.totals = "Records 1 - " + this.items.length + "  di " + this.totalRecords;
        this.selected=[];
        this.showSelectAllItems=false;
        this.isSelectAllItem=false;
      } catch (err) {
        this.showError("Si è verificato un errore: " + err.message);
      }
      this.loading = false;
    },
    onRefresh() {
      this.page = 0;
      this.items = [];
      this.totalRecords = null;
      this.infiniteId += 1;
      this.selected=[];
      this.showSelectAllItems=false;
      this.isSelectAllItem=false;
      this.activeFilters={...this.filters};
    },
    onCleanFilters() {
      this.filters = {};
    },

    initFilters(filters) {
      this.includeCarrier = false;
      this.setCarrierFilters(filters);
    },
    setCarrierFilters(filters) {
    },
    toggle(filters, field) {
      filters[field] = filters[field] ? null : true;
      this.$emit("refreshTable");
    },
    toggleColor(filters, field) {
      return filters[field] ? "primary" : null;
    },
    toggleCarrier(filters) {
      this.includeCarrier = !this.includeCarrier;
      this.setCarrierFilters(filters);
      this.$emit("refreshTable");
    },

    showDetails(item) {
      console.log("Show details ", item);
      this.item = item;
      this.dialogInfoVisible = true;
    },

    onVisitChanged(visitId, visitType) {
      console.log("Call onVisitChanged ", visitId, visitType);
      this.item = { id: visitId, sysType: visitType };
    },

    async exportCSV() {
      var csvContent = "data:text/csv;charset=utf-8,";
      const resp = await this.service.list(0, 9999, this.defaultSort.field, this.defaultSort.desc, this.filters, false);
      const allFilteredItems = resp.data;
      this.headers.forEach((col) => {
        if (col.value !== "action") csvContent += col.text + ";";
      });
      allFilteredItems.forEach((item) => {
        csvContent += "\n";
        this.headers.forEach((col) => {
          if (col.value !== "action") {
            var value = this.evaluateExpr(item, col);
            if (value === null) {
              csvContent += "" + ";";
            } else {
              csvContent += value + ";";
            }
          }
        });
      });
      csvContent.replace(/(^\[)|(\]$)/gm, "");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    //Copiata da dataTableMixin
    evaluateExpr(item, col) {
      if (item == null) return null;
      let value = item;
      const t = col.value.split(".");
      t.forEach((f) => {
        if (value == null) return null;
        value = value[f];
      });
      if (value == null) return null;
      let formatter = col.columnType === "Date" ? "formatDateTimeFromDate" : null;
      formatter = col.formatValue || formatter;
      if (formatter) {
        const formatterF = this.$options.filters[formatter];
        if (formatterF != null) value = formatterF(value);
        else console.warn("Filtro non presente: ", formatter);
      }
      if (typeof value == "object" && value.label) return value.label;
      return value;
    },
    onSelectAllItems(event){
      this.showSelectAllItems=(event.value && event.items.length!= this.totalRecords);
      this.isSelectAllItem=false;
    },
    onSelectItem(){
      this.showSelectAllItems=false;
      this.isSelectAllItem=false;
    },
    selectAllItems(){
      this.showSelectAllItems=false;
      this.isSelectAllItem=true;
    },
    async showAction(action){
      this.currentAction=action;
      this.$refs.actionDialog.dialogVisible=true;
      let response;
      if (!this.isSelectAllItem){
        const selectedId=this.selected.map(s=>s.id);
        response = await this.$api.yardData.checkAction(this.currentAction.name, selectedId);
      } else {
        const encFilters=encodeURIComponent(JSON.stringify(this.filters));
        response = await this.$api.yardData.checkActionAll(this.currentAction.name, encFilters);
      }
      this.$refs.actionDialog.setParams(response);
      this.$refs.actionDialog.errorMessage=response.message;
    },
    async showSingleAction(action, item){
      this.selected=[item];
      this.isSelectAllItem=false;
      this.showAction(action);
    },
    async execAction(){
      if (!this.$refs.actionDialog.$refs.actionForm.validate()) return;
      const actionParams=this.$refs.actionDialog.getParams();
      const selectedId=this.selected.map(s=>s.id);
      const request={visitsId:selectedId, params:actionParams};
      try{
        const response = await this.$api.yardData.execAction(this.currentAction.name, request);
        if(response.success) {
          this.$refs.actionDialog.dialogVisible=false;
          this.onRefresh();
        }
      } catch(e){
        this.$refs.actionDialog.errorMessage=e.detailMessage;
      }
    },
    toggleFilter(filter, value){
      let current=this.filters[filter];
      if (!current) {
        this.filters[filter]=[value];
        this.filters={...this.filters};
        return;
      }
      if (current.indexOf(value)<0) current.push(value);
      else current=current.filter(v=> v!=value);
      this.filters[filter]=current.length>0 ? current : null;
      this.filters={...this.filters};
    },
    isSelected(filter, value){
      let current=this.filters[filter];
      if (!current) {
        return false;
      }
      if (current.indexOf(value)<0) return false;
      return true;
    },
  },
  components: { RailTosService, InfiniteLoading, VisitDetails, EnumVisualization, CalendarFilter, DateTimeAdapter, ActionDialog },
  mixins: [FormatsMixins,  YardEnumMixin,  ToastMixin],
};
</script>

<style scoped>
.totals {
  z-index: 100;
  position: fixed;
  bottom: 40px;
  right: 100px;
  padding: 4px;
  border: 1px solid black;
  font-weight: bold;
  background: white;
}
.detailsPane {
  z-index: 200;
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  margin: 29 px;
  border: 1px solid black;
  font-weight: bold;
  background: white;
}
.v-data-table-header th {
  vertical-align: top;
  border: 1 px solid red;
}
</style>
