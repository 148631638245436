<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-text-field :label="tLabel('Destinazione')" v-model="destination" />
      </v-col>
    </v-row>
    <v-row v-if="showCurrent">
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-uppercase text-body-1">Current</v-card-title>
          <v-card-text>
            <v-row class="text-caption text-uppercase">
              <v-col cols="6">
                <span>Type</span><br />
                <span class="currentValue">
                  <v-icon v-if="currentType != '<>'" large :title="transportTypesMap[currentType]">{{ iconMap[currentType] }}</v-icon>
                  <span v-else>{{ diffValue }}</span>
                </span>
              </v-col>
              <v-col cols="6">
                <span>Destination</span><br />
                <span class="currentValue">{{ currentDestination | diffValuesFilter(diffValue) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import YardEnumMixin from "@/mixins/yard/EnumMixin";

export default {
  name: "SetHandlingOutTruck",
  props: ["visits"],
  data() {
    return {
      currentType: null,
      currentDestination: null,
      destination: null,
      diffValue: this.tLabel("Valori diversi"),
    };
  },
  computed: {
    showCurrent() {
      return this.currentType || this.currentDestination;
    },
  },
  async mounted() {
    this.destination = null;
  },

  methods: {
    getParams() {
      return { destination: this.destination };
    },
    setParams(value) {
      this.currentType = value.params.params.currentType;
      this.currentDestination = value.params.params.currentDestination;
      this.destination = null;
    },
  },
  filters: {
    diffValuesFilter: function (value, diffValue) {
      if (value === "<>") return diffValue;
      return value;
    },
  },
  components: {},
  mixins: [YardEnumMixin],
};
</script>

<style scoped></style>
