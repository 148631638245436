<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          :label="tLabel('Voyage code')"
          :items="listTrainVoyages"
          clearable
          v-model="voyage"
          @change="trainVoyageChanged"
          :item-text="voyageCodeRender"
          return-object
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete :label="tLabel('Targa Carro')" :items="wagons" clearable v-model="wagon" item-text="code" return-object />
      </v-col>
      <v-col cols="4">
        <v-text-field :label="tLabel('Destinazione')" v-model="destination" />
      </v-col>
    </v-row>

    <v-row v-if="showCurrent">
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-uppercase text-body-1">Current</v-card-title>
          <v-card-text>
            <v-row class="text-caption text-uppercase">
              <v-col cols="3">
                <span>Type</span><br />
                <span class="currentValue">
                  <v-icon v-if="currentType != '<>'" large :title="transportTypesMap[currentType]">{{ iconMap[currentType] }}</v-icon>
                  <span v-else>{{ diffValue }}</span>
                </span>
              </v-col>
              <v-col cols="3">
                <span>Voyage</span><br />
                <span class="currentValue">{{ currentVoyage | diffValuesFilter(diffValue) }}</span>
              </v-col>
              <v-col cols="3">
                <span>Wagon</span><br />
                <span class="currentValue">{{ currentWagon | diffValuesFilter(diffValue) }}</span>
              </v-col>
              <v-col cols="3">
                <span>Destination</span><br />
                <span class="currentValue">{{ currentDestination | diffValuesFilter(diffValue) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import YardEnumMixin from "@/mixins/yard/EnumMixin";
import moment from "moment";

export default {
  name: "SetHandlingOutTrain",
  props: ["visits"],
  data() {
    return {
      voyage: {},
      wagon: {},
      wagons: [],
      currentType: null,
      currentVoyage: null,
      currentWagon: null,
      currentDestination: null,
      destination: null,
      listTrainVoyages: [],
      listWagonLicensePlates: null,
      diffValue: this.tLabel("Valori diversi"),
    };
  },
  computed: {
    showCurrent() {
      return this.currentType || this.currentVoyage || this.currentWagon || this.currentDestination;
    }
  },
  async mounted() {
    this.voyage = {};
    this.wagon = {};
    this.destination = null;
    this.listTrainVoyages = await this.$api.yardData.listTrainVoyages();
  },

  methods: {
    getParams() {
      return { voyageId: this.voyage.id, voyageCode: this.voyage.code, wagonId: this.wagon.id, wagonCode: this.wagon.code, destination: this.destination };
    },
    setParams(value) {
      this.currentType = value.params.params.currentType;
      this.currentVoyage = value.params.params.currentVoyage;
      this.currentWagon = value.params.params.currentWagon;
      this.currentDestination = value.params.params.currentDestination;
      this.voyage = this.listTrainVoyages.find((v) => v.code == this.currentVoyage) || {};
      this.destination = null;
      this.trainVoyageChanged();
    },
    async trainVoyageChanged() {
      if (!this.voyage) this.voyage = {};
      if (!this.voyage.id) {
        this.wagons = [];
        return;
      }
      this.wagons = await this.$api.yardData.listWagonLicensePlates(this.voyage.id);
      this.wagon = this.wagons.find((w) => w.code == this.currentWagon) || {};
    },
    voyageCodeRender: (item) => item.code + " - " + (item.etd ? moment(item.etd).format("DD/MM/YYYY HH:mm") : "") + " - " + item.destination,
  },
  filters: {
    diffValuesFilter: function (value, diffValue) {
      if (value === "<>") return diffValue;
      return value;
    },
  },
  components: {},
  mixins: [YardEnumMixin],
};
</script>

<style scoped></style>
