<template>
    <v-container fluid>
      <v-row v.if="params.success">
        <v-col cols="4">
          <v-text-field :label="tLabel('Quantity')" v-model="qty" readonly />
        </v-col>
        <v-col cols="4">
          <v-text-field :label="tLabel('Splits quantity')" v-model="splits" placeholder="Qty1,Qty2,Qty3"  :rules="[rules.required]"/>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          Inserire quantità splits, separate da virgola (es 10,5,3  crea tre nuove visite, rispettivamente con quantità 10,5 e 3, togliendole dalla visita originale)
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import YardEnumMixin from "@/mixins/yard/EnumMixin";
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "SplitGood",
  props: ["visits"],
  data() {
    return {
      splits: null,
      params: {},
    };
  },
  computed: {
    qty(){
      if (this.params.success && this.visits.length>0) return this.visits[0].qty;
      return null;
    },
  },
  async mounted() {
  },

  methods: {
    getParams() {
      return { splits: this.splits };
    },
    setParams(value) {
      this.params=value;
      this.splits=null;
    },
  },
  filters: {
  },
  components: {},
  mixins: [YardEnumMixin, FormatsMixins],
};
</script>

<style scoped></style>
